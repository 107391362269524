import {combineReducers} from "redux"
import * as authReducer from "./user";
import * as appReducer from "./app";
import * as alertReducer from "./alert";
import * as setupReducer from "./setup";
import * as practicesReducer from "./practices";
import * as dentistsReducer from "./dentists";
export default ()=>
   combineReducers({
       ...authReducer,
       ...appReducer,
       ...alertReducer,
       ...setupReducer,
       ...practicesReducer,
       ...dentistsReducer,
   })