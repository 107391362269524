import { Action, ActionType } from "../../model/actionType";
import { Practices } from "../../model/practices";
import createReducer from "./createReducer";
const initialState:any = [];

export const practices = createReducer(initialState, {
  [ActionType.LOAD_PRACTICES](state: Practices, action: Action<Practices>) {
    return Object.assign({ ...state }, action.payload);
  },
  [ActionType.LOAD_REGIONS](state: Practices, action: Action<Practices>) {
    return Object.assign({ ...state }, action.payload);
  },

});
