import React from 'react'
import { Button, Modal, Message, Header} from 'semantic-ui-react'

interface successModalProps {
    message:string
    hideDetailsModal:any
}
const SuccessModal:React.FC<successModalProps>=(props)=>{
   return (
    <Modal
    open={true}
    onClose={props.hideDetailsModal}
    centered={false}
    size='small'
  >
    <Header color="green" icon='check circle' content='success' />
    <Modal.Content>
      <Message>
          <p>{props.message}</p>
        </Message>
    </Modal.Content>
    <Modal.Actions>
      <Button secondary={true} onClick={props.hideDetailsModal}>
        Close
      </Button>
    </Modal.Actions>
  </Modal>
   )
}

export default SuccessModal