import React from "react"
import {connect} from "react-redux"
import { withRouter } from "react-router-dom"
import SignIn from "../../components/SignIn"
import {RootState} from "../../model"
const Home = withRouter(({ 
  // location, user 
}) => {
    return (
      <div>
          <SignIn />
      </div>
  
    );
  });
  function mapStateToProps(state:RootState){
    return {
        // auth:state.auth,
        // alert:state.alert
        user:state.user
    }
}

export default connect(mapStateToProps)(Home)