import React from "react"
import {connect} from "react-redux"
import {RouteComponentProps,Router} from "react-router-dom"
import AppRoutes from "../../AppRoutes"
import {history} from "../../redux/configure"
import {User} from "../../model/user"
import {RootState} from "../../model"
import 'semantic-ui-css/semantic.css';
import '../../styles/css/style.css';



const App:React.FC<any>=(props?)=>{
    if(!props)
       return null
    return (
        <>
           <Router history={history} >
               <AppRoutes auth={props.auth} />
           </Router>
        </>
    )
}

function mapStateToProps(state:RootState){
    return {
        auth:state.auth,
        // alert:state.alert
    }
}


export default connect(mapStateToProps)(App)