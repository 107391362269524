import { HeaderSchema } from "./model";

export const API_URL: string =
  process.env.NODE_ENV === "development"
    ? "http://localhost:5008"
    : "https://apikingarthur.cdp.dental";

export interface MenuItem {
  pageId: number;
  name: string;
  icon: any;
  label: string;
  title: string;
  subMenus: Array<SubMenu>;
  role: Array<string>;
  hide?: boolean;
  customIcon?: string;
}
export interface SubMenu {
  pageId: number;
  name: string;
  icon: any;
  label: string;
  title: string;
  customIcon?: string;
  hide?: boolean;
  role?: Array<string>;
}
export const menusArray: Array<MenuItem> = [
  {
    pageId: 1,
    name: "dashboard",
    icon: "sliders vertical large",
    label: "DASHBOARD",
    title: "DASHBOARD",
    subMenus: [],
    role: [],
  },
];

export const timeArray = [
  { value: 0, text: "12:00 AM" },
  { value: 900000, text: "   12:15 AM"},
  { value: 1800000, text: "  12:30 AM"},
  { value: 2700000, text: "  12:45 AM"},
  { value: 3600000, text: "  1:00 AM"},
  { value: 4500000, text: "  1:15 AM"},
  { value: 5400000, text: "  1:30 AM"},
  { value: 6300000, text: "  1:45 AM"},
  { value: 7200000, text: "  2:00 AM"},
  { value: 8100000, text: "  2:15 AM"},
  { value: 9000000, text: "  2:30 AM"},
  { value: 9900000, text: "  2:45 AM"},
  { value: 10800000, text: " 3:00 AM"},
  { value: 11700000, text: " 3:15 AM"},
  { value: 12600000, text: " 3:30 AM"},
  { value: 13500000, text: " 3:45 AM"},
  { value: 14400000, text: " 4:00 AM"},
  { value: 15300000, text: " 4:15 AM"},
  { value: 16200000, text: " 4:30 AM"},
  { value: 17100000, text: " 4:45 AM"},
  { value: 18000000, text: " 5:00 AM"},
  { value: 18900000, text: " 5:15 AM"},
  { value: 19800000, text: " 5:30 AM"},
  { value: 20700000, text: " 5:45 AM"},
  { value: 21600000, text: " 6:00 AM"},
  { value: 22500000, text: " 6:15 AM"},
  { value: 23400000, text: " 6:30 AM"},
  { value: 24300000, text: " 6:45 AM"},
  { value: 25200000, text: " 7:00 AM"},
  { value: 26100000, text: " 7:15 AM"},
  { value: 27000000, text: " 7:30 AM"},
  { value: 27900000, text: " 7:45 AM"},
  { value: 28800000, text: " 8:00 AM"},
  { value: 29700000, text: " 8:15 AM"},
  { value: 30600000, text: " 8:30 AM"},
  { value: 31500000, text: " 8:45 AM"},
  { value: 32400000, text: " 9:00 AM"},
  { value: 33300000, text: " 9:15 AM"},
  { value: 34200000, text: " 9:30 AM"},
  { value: 35100000, text: " 9:45 AM"},
  { value: 36000000, text: " 10:00 AM"},
  { value: 36900000, text: " 10:15 AM"},
  { value: 37800000, text: " 10:30 AM"},
  { value: 38700000, text: " 10:45 AM"},
  { value: 39600000, text: " 11:00 AM"},
  { value: 40500000, text: " 11:15 AM"},
  { value: 41400000, text: " 11:30 AM"},
  { value: 42300000, text: " 11:45 AM"},
  { value: 43200000, text: " 12:00 PM"},
  { value: 44100000, text: " 12:15 PM"},
  { value: 45000000, text: " 12:30 PM"},
  { value: 45900000, text: " 12:45 PM"},
  { value: 46800000, text: " 1:00 PM"},
  { value: 47700000, text: " 1:15 PM"},
  { value: 48600000, text: " 1:30 PM"},
  { value: 49500000, text: " 1:45 PM"},
  { value: 50400000, text: " 2:00 PM"},
  { value: 51300000, text: " 2:15 PM"},
  { value: 52200000, text: " 2:30 PM"},
  { value: 53100000, text: " 2:45 PM"},
  { value: 54000000, text: " 3:00 PM"},
  { value: 54900000, text: " 3:15 PM"},
  { value: 55800000, text: " 3:30 PM"},
  { value: 56700000, text: " 3:45 PM"},
  { value: 57600000, text: " 4:00 PM"},
  { value: 58500000, text: " 4:15 PM"},
  { value: 59400000, text: " 4:30 PM"},
  { value: 60300000, text: " 4:45 PM"},
  { value: 61200000, text: " 5:00 PM"},
  { value: 62100000, text: " 5:15 PM"},
  { value: 63000000, text: " 5:30 PM"},
  { value: 63900000, text: " 5:45 PM"},
  { value: 64800000, text: " 6:00 PM"},
  { value: 65700000, text: " 6:15 PM"},
  { value: 66600000, text: " 6:30 PM"},
  { value: 67500000, text: " 6:45 PM"},
  { value: 68400000, text: " 7:00 PM"},
  { value: 69300000, text: " 7:15 PM"},
  { value: 70200000, text: " 7:30 PM"},
  { value: 71100000, text: " 7:45 PM"},
  { value: 72000000, text: " 8:00 PM"},
  { value: 72900000, text: " 8:15 PM"},
  { value: 73800000, text: " 8:30 PM"},
  { value: 74700000, text: " 8:45 PM"},
  { value: 75600000, text: " 9:00 PM"},
  { value: 76500000, text: " 9:15 PM"},
  { value: 77400000, text: " 9:30 PM"},
  { value: 78300000, text: " 9:45 PM"},
  { value: 79200000, text: " 10:00 PM"},
  { value: 80100000, text: " 10:15 PM"},
  { value: 81000000, text: " 10:30 PM"},
  { value: 81900000, text: " 10:45 PM"},
  { value: 82800000, text: " 11:00 PM"},
  { value: 83700000, text: " 11:15 PM"},
  { value: 84600000, text: " 11:30 PM"},
  { value: 85500000, text: " 11:45 PM"}
];

export const frequency = [
  {key:1, text:'15 Min' , value:15},
  {key:2, text:'60 Min' , value:60}
];

export const defaultHomePage = 'dashboard'
export const SuccessMessage = 'Query has been tested successfully and returned no errors!';
