import React, {useEffect, useState} from "react"
import Template from "./Template"
import {Table, Card, Button} from "semantic-ui-react";
import AddModal from "./AddModal";
import {useActions} from "../redux/actions";
import * as SetupActions from "../redux/actions/setup";
import {RootState} from "../model";
import {connect} from "react-redux";
import moment from "moment";
import { ListItems } from "../model/setup";
import PopUpModal from "./PopUpModal";


const SetUp:React.FC<any>=(props)=>{

  const initialState: any = {
    addModal : false,
    loading : false,
    editItem : false
  }
  const [state, setState] = useState(initialState);
  const [deletePopUpState, setDeletePopUpState] = useState({showPopUp: false, item: {}});
  const setupActions=useActions(SetupActions);

  useEffect(()=> {
    getData();
  }, [])

  const getData = () => {
    setTimeout(function() {
      setupActions.getSetupList()
    }, 0);
  }

  const openAddForm = () => {
    setState({...state, addModal : true});
  }

  const closeAddModal = () => {
    setState({...state, addModal : false, editItem : false});
  }

  const handleAddQuery = (data: any) => {
    closeAddModal();
    setupActions.saveQuerySetup(data)
      .then(() => {
        getData();
      });
  }

  const handleEdit = (item: ListItems) => {
    setState({...state, addModal: true, editItem: item});
  }

  const deleteInfo = () => {
    const { item }: any = deletePopUpState;
    const title = item.title.replace(/\s+/g, '');
    setupActions.removeSetup(item.id, title);
    setDeletePopUpState({showPopUp:false, item: {}});
  };

  const { loading, addModal, editItem } = state;
  const { setup } = props;
  return (
    <Template activeLink="test" >
      { addModal && <AddModal handleClose={closeAddModal} addQueries={ handleAddQuery} editItem={editItem}/>}
      <Card className="pageTitle">
        <Card.Content className="pb0">
          <h2 className="float-left mr10">Setup Queries</h2>
          <div className="topFilters">
            <Button primary className="mr10 mb10" onClick={openAddForm}> Add Query</Button>
          </div>
        </Card.Content>
      </Card>
      <Table celled padded>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell singleLine>Title</Table.HeaderCell>
            <Table.HeaderCell>Frequency</Table.HeaderCell>
            <Table.HeaderCell>Start Time</Table.HeaderCell>
            <Table.HeaderCell>End Time</Table.HeaderCell>
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {!loading && setup && setup.map((item:any) => {
            return(
              <Table.Row key={item.id}>
                <Table.Cell>{item.title}</Table.Cell>
                <Table.Cell>{item.refreshInterval}</Table.Cell>
                <Table.Cell>{item.startTime ? moment(item.startTime).utc().format('hh:mm A') : ''}</Table.Cell>
                <Table.Cell>{item.endTime ? moment(item.endTime).utc().format('hh:mm A') : ''}</Table.Cell>
                <Table.Cell>
                  <Button primary={true} onClick={() => handleEdit(item)}> Edit </Button>
                  <Button primary={false} color='red' onClick={() => setDeletePopUpState({showPopUp:true, item})}> Delete </Button>
                </Table.Cell>
              </Table.Row>
            )
          })}

        </Table.Body>
      </Table>
      {  deletePopUpState.showPopUp &&
        <PopUpModal
            headerText="Delete Selected Record"
            message="Are you sure you want to delete?"
            size='mini'
            action='delete'
            showLabel={true}
            hidePopUpModal={()=>setDeletePopUpState({showPopUp:false, item: {}})}
            deletePopUpHandler={deleteInfo}
        />
      }
    </Template>
  )
}
function mapStateToProps(state:RootState){
  return {
    setup:state.setup,
  }
}


export default connect(mapStateToProps)(SetUp)