import { Action, ActionType } from "../../model/actionType";
import { Dentists } from "../../model/dentists";
import createReducer from "./createReducer";
const initialState:any = [];

export const dentists = createReducer(initialState, {
  [ActionType.LOAD_DOCTORS](state: Dentists, action: Action<Dentists>) {
    return Object.assign({ ...state }, action.payload);
  },
});
