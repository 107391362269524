
export enum ActionType {
    //app-auth
    LOGIN_BEGIN,
    LOGIN_SUCCESS,
    LOGOUT,
    LOAD_SESSION,
    ALERT,
    LOADER,
    SEND_FEEDBACK,
    SAVE_USAGE,
    SIDEBAR_OPEN,
    LOAD_MYCDP_URI,
    LOAD_PRACTICES,
    LOAD_REGIONS,
    LOAD_DOCTORS,
   // Query set up
    SAVE_QUERY_SETUP,
    LOAD_SETUP,
    TEST_QUERY_SETUP
}



export interface Action<T> {
    type: ActionType;
    payload: T;
  }
  