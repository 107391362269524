import React from "react";
import { Grid, Modal, Button } from 'semantic-ui-react';
import { PopUpModals } from "../model";

function PopUpModal(props: PopUpModals) {
	const showPopupAsAlert = () => {
		return (
			<Grid>
				<label>{props.showLabel ? 'Warning, this cannot be undone!' : ''}</label>
				<Grid.Row>
					<Grid.Column>
						{props.message}
					</Grid.Column>
				</Grid.Row>
			</Grid>
		)
	};

	return (
		<Modal
			open={true}
			onClose={props.hidePopUpModal}
			aria-labelledby="ModalHeader"
			centered
			closeIcon
			size={props.size}
		>
			<Modal.Header>
				{props.headerText}
			</Modal.Header>
			<Modal.Content scrolling>
				{showPopupAsAlert()}
			</Modal.Content>
			{props.deletePopUpHandler && <Modal.Actions>
				<Button secondary onClick={props.hidePopUpModal}>Cancel</Button>
				<Button negative onClick={props.deletePopUpHandler}>Delete</Button>
			</Modal.Actions>}
		</Modal>
	);
}


export default PopUpModal;
