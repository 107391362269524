import React from "react"
import {Redirect,Route} from "react-router"
import {PrivateRoute, PrivateRouteProps} from "./components/PrivateRoute"
import Home from "./container/Home"
import SignIn from "./components/SignIn"
import SetUp from "./components/Setup"
import { defaultHomePage } from "./constants"
const AppRoutes:React.FC<any>=(props)=>{
    const isAuthenticated = !!(props.auth && props.auth.user && props.auth.user.id);
    const defaultProtectedRouteProps: PrivateRouteProps = {
        isAuthenticated: isAuthenticated,
        authenticationPath: "/"
    };
    const RedirectTo=(props:any)=>isAuthenticated?<Redirect to={`/${defaultHomePage}`}/> : <SignIn {...props} />
    return (
        <>
          <Route
            exact={true}
            path="/"
            render={props=>RedirectTo(props)}
            />
            <Route
                exact={true}
                path="/cdpLogin"
                component={Home}
            />
               <Route
                exact={true}
                path="/login"
                component={Home}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                exact={true}
                path="/dashboard"
                component={SetUp}
            />


        </>
    )
}
export default AppRoutes
