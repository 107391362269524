import { Action, ActionType } from "../../model/actionType";
import { Setup } from "../../model/setup";
import createReducer from "./createReducer";
const initialState:any = [];

export const setup = createReducer(initialState, {
  [ActionType.LOAD_SETUP](state: Setup, action: Action<Setup>) {
    return action.payload;
  },
});
