import {ActionType} from "../../model/actionType"
import {errorHandler} from "./auth"

export const saveQuerySetup = (params : any) => async (dispatch:Function,getState:Function,api:any)=>{
    const url = `setup/save`;
    dispatch({type:ActionType.LOADER,payload:true})
    try {
        const resp = await api.post(url, params);
        dispatch({ type: ActionType.SAVE_QUERY_SETUP, payload:  resp  });
        dispatch({type:ActionType.LOADER,payload:false})
        return resp;

    } catch(err) {
        dispatch({type:ActionType.LOADER,payload:false})
        return dispatch(errorHandler(err))
    }
}

export const getSetupList = () => async (dispatch:Function,getState:Function,api:any)=>{
    const url = `setup/get`;
    dispatch({type:ActionType.LOADER,payload:true})
    try {
        const resp = await api.get(url);
        dispatch({ type: ActionType.LOAD_SETUP, payload:  resp  });
        dispatch({type:ActionType.LOADER,payload:false})
        return resp;
    } catch(err) {
        dispatch({type:ActionType.LOADER,payload:false})
        return dispatch(errorHandler(err))
    }
}

/**
 * Run and test Query for open dental and tab32
 * @returns Message
 */
export const runAndTestQuery = (params: string, type: string) => async (dispatch: Function, _getState: Function, api: any)=>{
    const url = `setup/runAndTestQuery`;
    dispatch({type:ActionType.LOADER, payload:true})
    try {
        const query = params.replace(/\s+/g, ' ');
        const payload = { query, type };
        const resp = await api.post(url, payload);
        dispatch({type: ActionType.LOADER, payload: false})
        
        const data: any = [];
        if (type === 'tab32query') {
            for (const i in resp) {
                if (resp[i].length > 0 && resp[i][0].errors) {
                   const {message} = resp[i][0];
                   const value = { message, name: i };
                   data.push(value);
                }
            }
        } else if(type === 'ODquery') {
            for (const i in resp) {
                if (resp[i].length > 0 && resp[i][0].message) {
                   const {message} = resp[i][0];
                   const value = { message, name: i };
                   data.push(value);
                }
            }
        }

        return data;
    } catch(err) {
        dispatch({type: ActionType.LOADER, payload: false});
        return err;
    }
};

/**
 * Remove Setup Queries
 * @param id {number}
 */
export const removeSetup = (id: number, tableName: string) => async (dispatch: Function, _getState: Function, api: any) => {
    dispatch({type: ActionType.LOADER, payload: true})
    try {
        const result = await api.delete(`setup/delete/${id}/${tableName}`);
        dispatch({ type: ActionType.LOAD_SETUP, payload:  result  });
        dispatch({type:ActionType.LOADER,payload: false});
        return result;
    } catch(err) {
        dispatch({type:ActionType.LOADER,payload:false})
        return dispatch(errorHandler(err));
    }
};
