import React, { useState,useEffect} from "react"
import GoogleLogin from "react-google-login"
import CookieModal from "./CookieModal"
import {useActions} from "../redux/actions";
import * as Actions from "../redux/actions/auth";
import {Redirect} from "react-router";
import {Grid} from "semantic-ui-react";
import {defaultHomePage} from "../constants";




const SignIn:React.FC<any>=(props)=>{
   const initialState: any = {
     cookieModal : false,
     isAuthenticated : false
   }
    const [state, setState] = useState(initialState);
    const authActions=useActions(Actions)

    useEffect(()=>{
      const queryParams :any= new URLSearchParams(window.location.search);
      const accessToken = queryParams.get("token");
      if (accessToken && accessToken.length > 0) {

           authActions.cdpLogin(accessToken)
             .then((res:any) => {
               if(res.user &&res.user.id){
                 setState({...state, isAuthenticated: true})
               }
             })
      }
    },[])

    const doLogin = async (data: any) => {
      await authActions.LogIn(data.profileObj);
  };
  const onFailure = (response: any) => new Promise((resolve, reject) => {
    if (response && response.error === 'idpiframe_initialization_failed') {
      setState({...state, cookieModal: true});
    }
});
const closeCookieModal = () => {
  setState({...state, cookieModal: false});
}
return (

        <div className="login">
          { state.isAuthenticated && <Redirect to={`/${defaultHomePage}`}/>}
        {/* { (props.loginErr) && <ErrorModal hideDetailsModal={hideErrorModal} errorMessage={props.loginErr} /> } */}
        <div className="contentCenter container-fluid">
            <Grid>
                <Grid.Row>
                    <Grid.Column width={8} className='sectionLeft'>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <div className="row">
                            <div className="col-12">
                                <img src="/images/logoMain.png" className="align-self-center loginLogo" alt="Logo"></img>
                                <h1 className="ui header inverted mt-0 mb-4">SIGN IN</h1>
                                <GoogleLogin
                                    clientId="534451231648-d2a526h66llkn1e781gv6fgjvofsph2c.apps.googleusercontent.com"
                                    buttonText="Sign in with Google"
                                    className ="google"
                                    onSuccess={doLogin}
                                    onFailure={onFailure}
                                />
                                { state.cookieModal && <CookieModal handleClose={closeCookieModal}/>}
                                {/* <Button className="downloadApp mt30" onClick={this.downloadApp}>DOWNLOAD APP</Button> */}
                                {/* { this.state.downloadApp && <DownloadAppPopUp hideDetailsModal={this.hideDetailsModal} /> } */}
                            </div>
                        </div>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    </div>
  );
}
export default SignIn