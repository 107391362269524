import React from 'react'
import { Button, Modal, Message, Header, Table} from 'semantic-ui-react'


interface ErrorModalProps {
    errorDetail: any
    hideDetailsModal:any
}
const ErrorModal:React.FC<ErrorModalProps>=(props)=>{
  const {errorDetail} = props;
   return (
    <Modal
    open={true}
    onClose={props.hideDetailsModal}
    centered={false}
    size='small'
  >
    <Header color="red" icon='warning' content='Error' />
    <Modal.Content>
      <Message negative>
        <Table padded>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Practice</Table.HeaderCell>
              <Table.HeaderCell>Message</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          {errorDetail.length > 0 && errorDetail.map((i: any) => {
            return (
              <Table.Body>
                <Table.Row>
                  <Table.Cell>{i.name}</Table.Cell>
                  <Table.Cell>{i.message}</Table.Cell>
                </Table.Row>
              </Table.Body>
            )
          })}
        </Table>
        </Message>
    </Modal.Content>
    <Modal.Actions>
      <Button color='red' onClick={props.hideDetailsModal}>
        Close
      </Button>
    </Modal.Actions>
  </Modal>
   )
}

export default ErrorModal