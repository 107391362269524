import * as React from "react"
import {Provider} from "react-redux"
import {PersistGate} from "redux-persist/integration/react"
import { Spinner } from "reactstrap"
import App from "./container/App"
import configureStore from "./redux/configure"

const {persistStore,store}=configureStore()

const Loader=(
    <div style={{width:"100%"}}>
        <Spinner type="grow" color="dark" />
    </div>
)

const AppRedux:React.FC=()=>{
    return (
        <Provider store={store} >
            <PersistGate loading={Loader} persistor={persistStore}>
                <App/>
            </PersistGate>
        </Provider>
    )
}

export default AppRedux