import React, {useState} from "react"
import { connect } from "react-redux";
import {Button, Dropdown, Form, Modal, Dimmer, Loader} from 'semantic-ui-react';
import {frequency, SuccessMessage, timeArray} from "../constants";
import { RootState } from "../model";
import {useActions} from "../redux/actions";
import * as SetupActions from "../redux/actions/setup";
import ErrorModal from "./ErrorModal";
import SuccessModal from "./SuccessModal";

const AddModal:React.FC<any>=(props)=>{
  const {id, title, tab32query, ODquery, refreshInterval, startTime, endTime}  = props.editItem;
  const initialValues: any = {
    id : id ? id : 0,
    title : title ? title : "",
    tab32query : tab32query ? tab32query : "",
    ODquery : ODquery ? ODquery : "",
    refreshInterval : refreshInterval ? refreshInterval : "",
    startTime : startTime ? startTime : "",
    endTime : endTime ? endTime : ""
  }
  const [value, setValue] = useState(initialValues);
  const [errModal, setErrModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [errModalMessage, setErrModalMessage] = useState([]);
  const setupActions = useActions(SetupActions);

  const handleOnChange = (fieldValue: any, name: string) => {
    value[name] = fieldValue;
    setValue(value);
  }

  const handleubmit = () => {
    props.addQueries(value)
  }

  const handleModalMessage = (resp: any) => {
    if(resp.length > 0) {
      setErrModal(true);
      setErrModalMessage(resp);
    } else {
      setSuccessModal(true);
      setModalMessage(SuccessMessage);
    }
  }

  const testQuery = (param: string, type: string) => {
    setupActions.runAndTestQuery(param, type).then((resp: any) => {
      handleModalMessage(resp); 
    }).catch((err: any) => {
      console.error(err);
    });
  };

  const hideErrorModal = () => {
    setErrModal(false);
    setSuccessModal(false);
  }
  const {loader} = props.app;

    return (
        <Modal className={'modalCenter'}
        onClose={() => props.handleClose()}
        open={true}
 >
    <Modal.Header>Add Queries - King Arthur</Modal.Header>
    { loader && 
      <Dimmer active inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer> 
    }

    {successModal && <SuccessModal
                      hideDetailsModal={hideErrorModal}
                      message={modalMessage}
    />}
    {errModal && <ErrorModal
                  hideDetailsModal={hideErrorModal}
                  errorDetail={errModalMessage}
    />}
    <Modal.Content >
     <Modal.Description>
       <Form>
         <Form.Group widths='equal'>
           <Form.Input
             fluid
             label='Title'
             placeholder='Title'
             defaultValue={value.title}
             onChange={(event, data)=> handleOnChange(data.value, 'title')}
           />
           <Form.Field>
             <label>Refresh Interval</label>
             <Dropdown selection fluid
                defaultValue={value.refreshInterval ? value.refreshInterval : ''} 
                onChange={ (e, a) => handleOnChange(a.value, 'refreshInterval') }
                placeholder='Refresh Interval'
                name='refreshInterval'
                options={frequency}
             />
           </Form.Field>

         </Form.Group>
         <Form.Group widths='equal'>
           <Form.Field>
             <label>Start Time</label>
           <Dropdown selection fluid
              defaultValue={value.startTime ? value.startTime : ''}
              onChange={ (e, a) => handleOnChange(a.value, 'startTime') }
              placeholder='Select Start Time'
              name='startTime'
              options={timeArray}
           />
           </Form.Field>
            <Form.Field>
              <label>End Time</label>
               <Dropdown selection fluid
                  defaultValue={value.endTime ? value.endTime : ''}
                  onChange={ (e, a) => handleOnChange(a.value, 'endTime') }
                  placeholder='Select End Time'
                  name='endTime'
                  options={timeArray}
               />
            </Form.Field>
         </Form.Group>
         <Form.TextArea
           label='Open Dental Query'
           placeholder='Open Dental Query'
           defaultValue={value.ODquery}
           onChange={(event, data)=> handleOnChange(data.value, 'ODquery')}
         />
         <Button
            className="eleFloat"
            content="Test Query"
            primary={true}
            onClick={() => testQuery(value.ODquery, 'ODquery')}
          />
         <Form.TextArea
           label='Tab32 Query'
           placeholder='Tab32 Query'
           defaultValue={value.tab32query}
           onChange={(event, data)=> handleOnChange(data.value, 'tab32query')}
         />
         <Button
          className="eleFloat"
          content="Test Query"
          primary={true}
          onClick={() => testQuery(value.tab32query, 'tab32query')}
        />
       </Form>
     </Modal.Description>
   </Modal.Content>
   <Modal.Actions>
     <Button
       content="Cancel"
       secondary={true}
       onClick={() => props.handleClose()}
     />

     <Button
         content="Add"
         primary={true}
         onClick={handleubmit}
     />

   </Modal.Actions>
 </Modal>
    )
}

function mapStateToProps(state: RootState) {
  return {
    app: state.app,
  };
}

export default connect(mapStateToProps)(AddModal);
